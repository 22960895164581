import React, {useEffect, useState} from 'react';
import css from './mailAddress.module.scss';

const MailAddress = props => {
  const { login = '', domain = '', contentToDisplay = null, subject, className = '', color } = props;

  const [hrefVisible, setHrefVisible] = useState(false);
  const [addressToDisplay, setAddressToDisplay] = useState('');

  const nextSubject = subject ? `?subject=${subject}` : '';

  const mailTo = hrefVisible ? `mailto:${login}@${domain}${nextSubject}` : '';

  const domainSplitByDot = domain.split('.');

  const getColor = () => {
    const propsColor = !!color ? color : 'mainRed';
    switch (propsColor) {
      case 'green':
        return 'var(--mainGreenColor)';
      default:
        return 'var(--mainRedColor)';
    }
  };

  const renderDomain = () => {
    return domainSplitByDot.map((domainPart, index) => {
      const dot = (index === domainSplitByDot.length - 1) ? '' : '.';
      return (
        <span key={`m_d_${index}`}>
          <span className={css.m4}>{domainPart}{dot}</span>
          <span className={css.m0}>ann{index}@dolor.com</span>
        </span>
      )
    });
  };

  const handleMouseOver = (event) => {
    event.preventDefault();
    setHrefVisible(true);
  };

  const handleMouseOut = (event) => {
    event.preventDefault();
    setHrefVisible(false);
  };


  useEffect(() => {
    setAddressToDisplay((
      <span className={css.m}>
        <span className={css.m0}>ed@sitamet</span>
        <span className={css.m1}>{login}</span>
        <span className={css.m0}>john@ipsum.com</span>
        <span className={css.m2}>@</span>
        <span className={css.m0}>doe@lorem</span>
        <span className={css.m3}>{renderDomain()}</span>
      </span>
    ));
  },[]);

  return (
    <a
      href={mailTo}
      onMouseOver={(e) => handleMouseOver(e)}
      onMouseOut={(e) => handleMouseOut(e)}
      onFocus={(e) => handleMouseOver(e)}
      onBlur={(e) => handleMouseOut(e)}
      target="_blank"
      className={className}
      style={{ color: getColor() }}
    >
      {contentToDisplay || addressToDisplay}
    </a>
  )
};

export default MailAddress;
