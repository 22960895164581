import React from "react";
import css from './contactUs.module.scss';
import {useTranslation} from "react-i18next";
import CompanyContactMail2 from "../../../shared/CompanyContactMail/CompanyContactMail2";
import DeeplaiButton from "../../../shared/DeeplaiButton/DeeplaiButton";


const ContactUs = () => {
  const { t } = useTranslation();


  return (
    <div className={css.wrapper}>
      <div className={css.fullPageWidthBg}></div>
        <div className={css.backgroundImage} />
        
          <div className={css.textWrapper}>
            <div className={css.logo}>
                <DeeplaiButton version={"black"} height={'50px'} />
             </div>
            <div className={css.title}>
            <div>Need Help? Contact Us!</div>
            
            <div className={css.text}>
            
                <button className={css.button}> <CompanyContactMail2 color={'green'} /></button> 
          
            </div>
          
       
        </div>
        </div>    

      </div> 
     
  )
};

export default ContactUs;
