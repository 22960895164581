import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';
import css from './companyContactMail2.module.scss';
import MailAddress2 from "../MailAddress/MailAddress2";

const CompanyContactMail2 = props => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            mailLogins {
              contact
            },
            mailDomains {
              deeplai   
            }
          }
        }
      }
    `
  );

  const login = site.siteMetadata.mailLogins.contact;
  const domain = site.siteMetadata.mailDomains.deeplai;

  return (
    <MailAddress2
      login={login}
      domain={domain}
      className={css.link}
      contentToDisplay={props.contentToDisplay}
      subject={props.subject}
      color={props.color}
    />

  )
};

export default CompanyContactMail2;
