import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import {useTranslation} from 'react-i18next';
import css from './contact.module.scss';
import Footer from "../components/shared/Footer/Footer";
import ContactInfo from "../components/pages/Contact/partials/contactInfo/ContactInfo";
import ContactUs from "../components/pages/Contact/partials/ContactUs";
import { getRoutes } from '../components/shared/routes';
import React from 'react';

const ContactPage = () => {
  const { t } = useTranslation();
  const pageTitle = `${getRoutes().Contact.pageTitle}`;


  return (
    <Layout>
      <SEO title={pageTitle} description={'Reach out details'}/>

      <div className={css.wrapper}>

        <div className={css.ContactInfo}>
          <ContactInfo />
        </div>

        <div className={css.ContactUs}>
          <ContactUs />
        </div>

        <div className={css.Footer}>
          <Footer/>
        </div>

      </div>
    </Layout>
  );
};

export default ContactPage;

