import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';
import css from './companySalesMail.module.scss';
import MailAddress from "../MailAddress/MailAddress";

const CompanySalesMail = props => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            mailLogins {
              sales
            },
            mailDomains {
              deeplai   
            } 
          }
        }
      }
    `
  );

  const login = site.siteMetadata.mailLogins.sales;
  const domain = site.siteMetadata.mailDomains.deeplai;

  return (
    <MailAddress
      login={login}
      domain={domain}
      className={css.link}
      contentToDisplay={props.contentToDisplay}
      subject={props.subject}
      color={props.color}
    />
  )
};

export default CompanySalesMail;
